<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group
              label="Cover"
              invalid-feedback="Cover are required"
              required
              ref="docs"
            >
              <!-- https://cdn.cloudious.net/file-1678189226453-406060527.png -->
              <div>
                <b-spinner
                  v-if="docLoading"
                  type="grow"
                  label="Loading..."
                  variant="primary"
                  class="m-5"
                />
                <b-img
                  ref="previewEl"
                  src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                  @click="$refs.mediaDoc.click()"
                  v-else-if="!mediaLoaded"
                  style="border-radius: 10px"
                  height="120px"
                  width="120px"
                />
                <div v-else class="d-flex align-items-center">
                  <b-img
                    v-if="isImage(myObj.media)"
                    ref="previewEl"
                    :src="myObj.media"
                    @click="$refs.mediaDoc.click()"
                    style="border-radius: 10px"
                    height="120px"
                    width="120px"
                  />
                  <video
                    v-else-if="isVideo(myObj.media)"
                    controls
                    controlslist="nodownload"
                    style="height: 120px; width: 120px"
                  >
                    <source :src="myObj.media" type="video/mp4" />
                  </video>
                  <a :href="myObj.media" target="_blank" v-else>
                    <div class="file-type">
                      {{ myObj.media.split(".").pop().toUpperCase() }}
                    </div>
                  </a>
                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="text-danger cursor-pointer ml-1"
                    @click="removeMedia()"
                  />
                </div>
                <input
                  type="file"
                  id="mediaDoc"
                  hidden
                  ref="mediaDoc"
                  @change="addMedia()"
                  accept="image/*,.pdf,video/mp4"
                />
              </div>

              <!-- <b-button
                ref="docs"
                @click="$refs.mediaDoc.click()"
                variant="primary"
                :disabled="request || docLoading"
                block
              >
              </b-button> -->
            </b-form-group>
          </b-col>
          <!-- <b-col
            md="12"
            v-if="mediaLoaded"
            style="display: flex; align-items: center"
          >
            <div class="file-type">
              {{ myObj.media.split(".").pop().toUpperCase() }}
            </div>
           
          </b-col> -->

          <b-col md="12">
            <b-form-group label="Title " invalid-feedback="Title is required.">
              <b-form-input
                placeholder="Enter tile."
                ref="title"
                v-model="myObj.title"
                @focusout="CheckTitle()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Message"
              invalid-feedback="Message is required."
              ref="msg"
            >
              <b-form-textarea
                ref="msg"
                placeholder="Enter message."
                v-model="myObj.message"
                @focusout="CheckMessage()"
                rows="10"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Send on"
              invalid-feedback="Send on is required."
              ref="send"
            >
              <v-select
                ref="send"
                v-model="myObj.sendOn"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sendOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="send on"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <!-- <b-row>
        <b-col md="7">
          <b-form-group label="Title " invalid-feedback="Title is required.">
            <b-form-input
              placeholder="Enter tile."
              ref="title"
              v-model="myObj.title"
              @focusout="CheckTitle()"
            />
          </b-form-group>

          <b-form-group
            label="Message"
            invalid-feedback="Messgae is required."
            ref="msg"
          >
            <b-form-textarea
              ref="msg"
              placeholder="Enter message."
              v-model="myObj.message"
              @focusout="CheckMessage()"
              rows="5"
            ></b-form-textarea>
          </b-form-group>

          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              :disabled="request"
              @click="Add()"
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> Save </span>
            </b-button>
          </div>
        </b-col>
        <b-col md="5">
          <b-row>
            <b-col md="12">
              <b-input-group class="input-group-merge w-100">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-prepend>
                <b-form-input v-model="searchQuery" placeholder="Search..." />
              </b-input-group>
            </b-col>

            <b-col
              md="12"
              class="mt-1"
              style="display: flex; cursor: context-menu"
              v-for="item in items"
              :key="item.id"
              @click="Edit(item.id)"
            >
              <b-avatar class="mb-1" variant="light-primary" size="35">
                <span style="font-size: 20px">{{
                  item.title.substring(0, 1)
                }}</span>
              </b-avatar>
              <div class="" style="margin-left: 5px">
                <h4 class="font-weight-bolder mb-0" style="margin-top: 5px">
                  {{ item.title.substring(1) }}
                </h4>
                <p style="max-height: 1.5em; overflow: hidden">
                  {{ item.message }}
                </p>
              </div>
            </b-col>
        
          </b-row>
        </b-col>
      </b-row> -->

      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="AddOpen()"
            style="width: 100%"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Template</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="filters.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
        :selectable="rights.edit"
        select-mode="single"
        @row-selected="Edit($event[0].id)"
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #head(actions)="data">
          <div class="mr-5 text-right">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-right">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.top
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Edit(data.item.id)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.top
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BAvatar,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BImg,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.title.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.message.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      titleText: "This is title",
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Template",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "title", key: "title" },
        { label: "message", key: "message" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        title: "",
        message: "",
        campusID: this.$store.state.userData.cId,
        media: "",
      },

      sendOptions: [
        { text: "None", value: "" },
        { text: "Fee Challan", value: "Fee Challan" },
        {
          text: "Fee Challan (family-wise)",
          value: "Fee Challan (family-wise)",
        },
        { text: "Defaulters", value: "Defaulters" },
        { text: "Fee Collection", value: "Fee Collection" },
        { text: "Student (checkin)", value: "present" },
        { text: "Student Absent", value: "absent" },
        { text: "Student Late", value: "late" },
        { text: "Student Leave", value: "leave" },
        { text: "Student (checkout)", value: "timeout" },
        { text: "Staff Attendance (in)", value: "Staff Attendance" },
        {
          text: "Staff Attendance (out)",
          value: "Staff Attendance (out)",
        },
        { text: "Examination", value: "Examination" },
        { text: "Inquiry", value: "Inquiry" },
        { text: "Admission", value: "Admission" },
        { text: "Salary Slip", value: "Salary slip" },
        { text: "Result", value: "Result" },
        { text: "Subject Marks", value: "subject marks" },
        { text: "Subject Marks (Absent)", value: "subject marks (Absent)" },
      ],
      detailObj: {},
      timeout: 10000,
      mediaLoaded: false,
      docLoading: false,
    };
  },
  validations: {
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "MessageTemplate/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      if (this.myObj.media == null || this.myObj.media == "")
        this.mediaLoaded = false;
      else this.mediaLoaded = true;

      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["msg"];
      elem.state = undefined;

      this.sidebarTitle = "Edit Template";
      this.sidebarButton = "Update";
      this.visibility = true;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        title: "",
        message: "",
        sendOn: "",
        media: "",
        campusID: this.$store.state.userData.cId,
      };
      this.mediaLoaded = false;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["msg"];
      elem.state = undefined;

      this.sidebarTitle = "Add Template";
      this.sidebarButton = "Save";
      this.visibility = true;
    },

    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMessage() {
      var elem = this.$refs["msg"];
      if (this.myObj.message == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4)$/.test(url);
    },
    isDoc(url) {
      return /\.(pdf)$/.test(url);
    },
    removeMedia() {
      this.myObj.media = null;
      this.mediaLoaded = false;
      this.$refs.mediaDoc.files = null;
    },
    addMedia() {
      let media = this.$refs.mediaDoc.files[0];
      if (media != "") {
        this.mediaLoaded = false;
        this.docLoading = true;
        let formData = new FormData();
        formData.append("file", media);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.myObj.media = resp;
            this.docLoading = false;
            this.mediaLoaded = true;
            // console.log(this.myObj.media);
          });
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "MessageTemplate?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.dataLoading = false;
      // console.log("temp", this.items);
    },

    async Add() {
      this.CheckTitle();
      this.CheckMessage();
      if (this.CheckTitle() == false || this.CheckMessage() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          // console.log("obj:", this.myObj);
          var status = await this.post({
            url:
              this.$store.state.domain +
              "MessageTemplate?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Template added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) this.LoadData();
          this.visibility = false;
        } else {
          //Edit
          this.request = true;
          // console.log("edit_obj:", this.myObj);
          var status = await this.put({
            url:
              this.$store.state.domain +
              "MessageTemplate/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Template updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) this.LoadData();
          this.visibility = false;
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "MessageTemplate/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Template removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
